import axios, { AxiosResponse } from "axios";
import { logOutUser } from "../features/Auth/AuthSlice";

export const setupInterceptors = (store: any) => {
  // axios.defaults.baseURL = 'https://phr-resource-server:3000';
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.validateStatus = () => true;
  // Include credentials with requests
  axios.defaults.withCredentials = true;

  // axios.interceptors.request.use(
  //   (request: any) => {
  //     const token = localStorage.getItem("token");
  //     request.headers.Authorization = `Bearer ${token}`;
  //     return request;
  //   },
  //   (error: any) => Promise.reject(error)
  // );

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      const { status } = response;
      if (status > 205 && status !== 201) {
        switch (status) {
          case 500:
            throw new Error(response.data);
          case 403:
            store.dispatch(logOutUser());
            throw new Error(response.data);
          case 401:
            store.dispatch(logOutUser()); // logout and redirect to login page
            throw new Error(response.data);
          case 400:
            throw response.data;
          case 422:
            throw response.data;
          case 409:
            throw response;
          case 204:
          case 206:
            throw response.data;
          case 501:
            throw response;
          default:
            throw new Error(response.data);
        }
      } else {
        return response;
      }
    },
    () => {   
      throw new Error("Network Error");
    }
  );
};
