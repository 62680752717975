import React, { Suspense, useEffect, useMemo, useRef } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";

import jwt from "jwt-decode";
import { useAppDispatch } from "../app/hooks";
import { PrivateRoute } from "./PrivateRoute";
import { Profile } from "../pages/Account/Profile";
import { ResourcesApi } from "../pages/ResourcesApi/ResourcesApi";

import Level1User from "../pages/Level1User/Level1User";
import Roles from "../pages/Roles/Roles";
import { scopeProperty } from "../global/scopePayload";
import axios from "axios";
// import Login from "../pages/Login/Login";
// import NoAccess from "../pages/NoAccess/NoAccess";
// import ResourceTypeView from "../pages/ResourceType/ResourceTypeView";
// import ResourceTopicView from "../pages/ResourceTopic/ResourceTopicView";
// import NotificationView from "../pages/Notification/NotificationView";
// import UserManagement from "../pages/UserManagement/UserManagement";
// import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
// import ResetPassword from "../pages/ForgotPassword/ResetPassword";
// import ResourceList from "../pages/Resource/ResourceList";
// import ResourceView from "../pages/Resource/ResourceView";
// import ResourceForm from "../pages/Resource/ResourceForm";
// import ResourceType from "../pages/ResourceTopic/ResourceTopic";
// import ResourceTopic from "../pages/ResourceTopic/ResourceTopic";
// import Notification from "../pages/Notification/Notification";
// import ResourceLibrary from "../pages/ResourceLibrary/ResourceLibrary";
// import Trust from "../pages/Trust/TrustList";

const Login = React.lazy(() => import("../pages/Login/Login"));
const ResourceList = React.lazy(() => import("../pages/Resource/ResourceList"));
const ResourceView = React.lazy(() => import("../pages/Resource/ResourceView"));
const ResourceForm = React.lazy(() => import("../pages/Resource/ResourceForm"));
const ForgotPassword = React.lazy(
  () => import("../pages/ForgotPassword/ForgotPassword")
);
const ResetPassword = React.lazy(
  () => import("../pages/ForgotPassword/ResetPassword")
);
const Resource = React.lazy(() => import("../pages/Resource/Resource"));
const UserManagement = React.lazy(
  () => import("../pages/UserManagement/UserManagement")
);
const Trust = React.lazy(() => import("../pages/Trust/TrustList"));
const Notification = React.lazy(
  () => import("../pages/Notification/Notification")
);
const NotificationView = React.lazy(
  () => import("../pages/Notification/NotificationView")
);
const NoAccess = React.lazy(() => import("../pages/NoAccess/NoAccess"));
const ResourceLibrary = React.lazy(
  () => import("../pages/ResourceLibrary/ResourceLibrary")
);
const ResourceType = React.lazy(
  () => import("../pages/ResourceTopic/ResourceTopic")
);
const ResourceTopic = React.lazy(
  () => import("../pages/ResourceTopic/ResourceTopic")
);
const ResourceTopicView = React.lazy(
  () => import("../pages/ResourceTopic/ResourceTopicView")
);
const ResourceTypeView = React.lazy(
  () => import("../pages/ResourceType/ResourceTypeView")
);

function CheckValidLogin() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const hasFetched = useRef(false);

  // Memoize token to avoid triggering `useEffect` on minor changes
  const memoizedToken = useMemo(() => token, [token]);

  useEffect(() => {
    async function checkLogin() {
      localStorage.clear();
      try {
        const { data } = await axios.get(
          `/api/oauth/login-check?userTkn=${memoizedToken}`
        );
        if (data) {
          const jwtDecode: any = jwt(data.data.token);
          localStorage.setItem("username", jwtDecode.name);
          localStorage.setItem("token", data.data.token);
          const shortenRoles = jwtDecode?.scopePermissions || [];
          let indexUrl = "/resource";

          if (
            shortenRoles?.includes("all") &&
            Object.keys(jwtDecode).includes("maiaScope")
          ) {
            indexUrl = "/notification-centre";
          } else if (shortenRoles?.includes("all")) {
            indexUrl = "/systemAdmin";
          } else if (shortenRoles?.includes("L1")) {
            indexUrl = "/trust";
          } else if (shortenRoles?.includes("user:create")) {
            indexUrl = "/user-management";
          } else if (
            shortenRoles?.includes("resource:create") ||
            shortenRoles?.includes("resource:approve") ||
            shortenRoles?.includes("resource:publish")
          ) {
            indexUrl = "/resource";
          }
          window.location.href = indexUrl;
        }
      } catch (error) {
        console.log("error", error);
      }
    }

    if (!hasFetched.current && memoizedToken) {
      hasFetched.current = true;
      checkLogin();
    }
  }, [memoizedToken, dispatch]);

  return <>Loading...</>;
}

export const AppRoutes = () => {
  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 1000);
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/validate-Login" element={<CheckValidLogin />} />
        <Route
          path="/"
          element={
            process.env.REACT_APP_LOGIN_TYPE === "SSO" ? <Login /> : <Login />
          }
        />
        <Route
          path="/login"
          element={
            process.env.REACT_APP_LOGIN_TYPE === "SSO" ? (
              <NoAccess />
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/forgot-password"
          element={
            process.env.REACT_APP_LOGIN_TYPE === "SSO" ? (
              <NoAccess />
            ) : (
              <ForgotPassword />
            )
          }
        />
        <Route
          path="/reset-password"
          element={
            process.env.REACT_APP_LOGIN_TYPE === "SSO" ? (
              <NoAccess />
            ) : (
              <ResetPassword />
            )
          }
        />
        <Route
          path="/set-password"
          element={
            process.env.REACT_APP_LOGIN_TYPE === "SSO" ? (
              <NoAccess />
            ) : (
              <ResetPassword />
            )
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute
              element={<Profile />}
              accessRoles={[
                scopeProperty.RESOURCE_VIEW,
                scopeProperty.RESOURCE_CREATE,
                scopeProperty.RESOURCE_APPROVE,
                scopeProperty.RESOURCE_PUBLISH,
                scopeProperty.USER_CREATE,
                scopeProperty.ALL_ACCESS,
                scopeProperty.RESOURCE_PUBLISHED_VIEW,
              ]}
            />
          }
        />

        <Route path="/endpoint" element={<ResourcesApi />} />
        <Route
          path="/resource"
          element={
            <PrivateRoute
              element={<ResourceList />}
              accessRoles={[
                scopeProperty.RESOURCE_VIEW,
                scopeProperty.RESOURCE_CREATE,
                scopeProperty.RESOURCE_APPROVE,
                scopeProperty.RESOURCE_PUBLISH,
                scopeProperty.RESOURCE_PUBLISHED_VIEW,
                scopeProperty.ALL_ACCESS,
              ]}
            />
          }
        />
        <Route
          path="/resource/view/:id"
          element={
            <PrivateRoute
              element={<ResourceView />}
              accessRoles={[
                scopeProperty.RESOURCE_VIEW,
                scopeProperty.RESOURCE_CREATE,
                scopeProperty.RESOURCE_APPROVE,
                scopeProperty.RESOURCE_PUBLISH,
                scopeProperty.ALL_ACCESS,
                scopeProperty.RESOURCE_PUBLISHED_VIEW,
              ]}
            />
          }
        />
        <Route
          path="/resource/edit/:id"
          element={
            <PrivateRoute
              element={<ResourceForm />}
              accessRoles={[scopeProperty.RESOURCE_CREATE]}
            />
          }
        />
        <Route
          path="/resource/create"
          element={
            <PrivateRoute
              element={<ResourceForm />}
              accessRoles={[scopeProperty.RESOURCE_CREATE]}
            />
          }
        />

        <Route
          path="/user-management"
          element={
            <PrivateRoute
              element={<UserManagement />}
              accessRoles={[scopeProperty.USER_CREATE]}
            />
          }
        />
        <Route
          path="/trust"
          element={
            <PrivateRoute
              element={<Trust />}
              accessRoles={[scopeProperty.ALL_TRUST_ACCESS]}
            />
          }
        />
        <Route
          path="/systemAdmin"
          element={
            <PrivateRoute
              element={<Level1User />}
              accessRoles={[scopeProperty.ALL_ACCESS]}
            />
          }
        />
        <Route
          path="/roles"
          element={
            <PrivateRoute
              element={<Roles />}
              accessRoles={[scopeProperty.ALL_ACCESS]}
            />
          }
        />
        <Route
          path="/notification-centre"
          element={
            <PrivateRoute
              element={<Notification />}
              accessRoles={[
                scopeProperty.RESOURCE_CREATE,
                scopeProperty.RESOURCE_APPROVE,
                scopeProperty.RESOURCE_PUBLISH,
                scopeProperty.USER_CREATE,
                scopeProperty.ALL_ACCESS,
                scopeProperty.RESOURCE_VIEW,
                scopeProperty.RESOURCE_PUBLISHED_VIEW,
              ]}
            />
          }
        />
        <Route
          path="/notification/view/:id"
          element={
            <PrivateRoute
              element={<NotificationView />}
              accessRoles={[
                scopeProperty.RESOURCE_CREATE,
                scopeProperty.RESOURCE_APPROVE,
                scopeProperty.RESOURCE_PUBLISH,
                scopeProperty.USER_CREATE,
                scopeProperty.ALL_ACCESS,
                scopeProperty.RESOURCE_VIEW,
                scopeProperty.RESOURCE_PUBLISHED_VIEW,
              ]}
            />
          }
        />
        <Route
          path="/resource-library"
          element={
            <PrivateRoute
              element={<ResourceLibrary />}
              accessRoles={[
                scopeProperty.RESOURCE_CREATE,
                scopeProperty.RESOURCE_PUBLISH,
                scopeProperty.ALL_ACCESS,
                scopeProperty.RESOURCE_VIEW,
                scopeProperty.RESOURCE_PUBLISHED_VIEW,
                scopeProperty.RESOURCE_APPROVE,
              ]}
            />
          }
        />
        <Route
          path="/resource-type"
          element={
            <PrivateRoute
              element={<ResourceType />}
              accessRoles={[scopeProperty.RESOURCE_CREATE]}
            />
          }
        />
        <Route
          path="/resource-topic"
          element={
            <PrivateRoute
              element={<ResourceTopic />}
              accessRoles={[scopeProperty.RESOURCE_CREATE]}
            />
          }
        />
        <Route
          path="/resource-topics/view/:id"
          element={
            <PrivateRoute
              element={<ResourceTopicView />}
              accessRoles={[
                scopeProperty.RESOURCE_CREATE,
                scopeProperty.ALL_ACCESS,
              ]}
            />
          }
        />
        <Route
          path="/resource-topics/view/:id"
          element={
            <PrivateRoute
              element={<ResourceTopicView />}
              accessRoles={[
                scopeProperty.RESOURCE_CREATE,
                scopeProperty.ALL_ACCESS,
              ]}
            />
          }
        />
        <Route
          path="/resource-types/view/:id"
          element={
            <PrivateRoute
              element={<ResourceTypeView />}
              accessRoles={[
                scopeProperty.RESOURCE_CREATE,
                scopeProperty.ALL_ACCESS,
              ]}
            />
          }
        />

        <Route path="/no-access" element={<NoAccess />} />
      </Routes>
    </Suspense>
  );
};
