import { Navigate, useLocation } from "react-router-dom";
import jwt from "jwt-decode";

export const PrivateRoute = ({ element: Component, accessRoles }) => {
  const location = useLocation();

  const checkAccess = () => {
    const token = localStorage.getItem("token");
    if (!token) return false;

    const jwtDecode = jwt(token || "");
    const shortenRoles = jwtDecode.scopePermissions || [];

    return shortenRoles.some((role) => accessRoles.includes(role));
  };

  if (!localStorage.getItem("token")) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (!checkAccess()) {
    return <Navigate to="/no-access" state={{ from: location }} />;
  }

  return Component;
};