import React from "react";

interface ApiAccordionProps {
  head: string;
  content?: string;
  children: any;
  addclass?: string;
  onAction: (e: any) => void
  mainIndex?: number;
  accordionId?: any;
  setAccordionId?: any;
}

export const ApiAccordion: React.FC<ApiAccordionProps> = ({
  head,
  content,
  children,
  addclass,
  onAction,
  mainIndex,
  accordionId,
  setAccordionId
}) => {
  const tabIndex = 0;
  return (
    <div className="accordion-wrap">
      <button
        type="button"
        tabIndex={tabIndex}
        className={`accordion-btn ${addclass}`}
        aria-expanded={accordionId === mainIndex ? "true" : "false"}
        onClick={() => {
          onAction({ type: 'acc_heading', index: 0 });
          if(accordionId === mainIndex){
            setAccordionId("")
          } else{
            setAccordionId(mainIndex);
          }
         }
        }
      >
        {head}
      </button>
      {accordionId === mainIndex && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};
