import React from "react";
import "./styles/Pagination.css";

const Pagination = (props: any) => {
  const { active, size, step, onClickHandler } = props;

  const showingNumbers = step * 2 + 1;
  let startNumber = 2;
  let startArrayNumber = props.step;

  let needStartDots = false;
  let needEndDots = false;

  if (active > step) {
    startArrayNumber = active - step;

    needStartDots = active > step + startNumber ? true : false;
  }

  if (size > showingNumbers) {
    needEndDots = size > active + step + 1 ? true : false;

    if (size < active + step + 1) {
      startArrayNumber = size - showingNumbers;
    }
  }

  let contentNumber;
  const tabIndex = 0;

  return (
    <div className="w-100">
      <ul className="pagination flex justify-center">
        {active > 1 ? (
          <li
            tabIndex={tabIndex}
            className="page-item prev arrow-icon onkeyup"
            onClick={() => onClickHandler(active - 1)}
          >
            &#x2039;
          </li>
        ) : (
          <li className="page-item prev arrow-icon disabled">&#x2039;</li>
        )}
        {size > showingNumbers + startNumber ? (
          <React.Fragment>
            <li
              tabIndex={tabIndex}
              onClick={(e) => onClickHandler(e.currentTarget.textContent)}
              className={`page-item onkeyup ${active === 1 && "active"}`}
            >
              1
            </li>

            {needStartDots && <span>...</span>}
            {[...Array(showingNumbers)].map((elementInArray, i) => (
              <li
                tabIndex={tabIndex}
                key={i++}
                {...(contentNumber = needStartDots
                  ? startArrayNumber
                  : startNumber)}
                // {...startNumber++}
                // {...startArrayNumber++}
                className={`page-item onkeyup ${
                  active === contentNumber && "active"
                }`}
                onClick={(e) => onClickHandler(e.currentTarget.textContent)}
              >
                {contentNumber}
              </li>
            ))}
            {needEndDots && <span>...</span>}
            <li
              tabIndex={tabIndex}
              className={`page-item onkeyup ${active === size && "active"}`}
              onClick={(e) => onClickHandler(e.currentTarget.textContent)}
            >
              {size}
            </li>
          </React.Fragment>
        ) : (
          ((startArrayNumber = 1),
          [...Array(size)].map((elementInArray, i) => (
            <li
              tabIndex={tabIndex}
              key={i++}
              className={`page-item onkeyup ${
                active === startArrayNumber && "active"
              }`}
              onClick={(e) => onClickHandler(e.currentTarget.textContent)}
            >
              {startArrayNumber++}
            </li>
          )))
        )}
        {active < size ? (
          <li
            tabIndex={tabIndex}
            className="page-item next arrow-icon onkeyup"
            onClick={() => onClickHandler(active + 1)}
          >
            &#8250;
          </li>
        ) : (
          <li className="page-item next arrow-icon disabled">&#8250;</li>
        )}
      </ul>
    </div>
  );
};

export default Pagination;
