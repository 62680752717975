import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
// import { RootState } from '../../app/store';
// import jwt from "jwt-decode";


export const createRole = createAsyncThunk(
  "admin/create-role",
  async (postData: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "POST",
          url: `/api/admin/create-role`,
          data: postData
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getRoleAndScope = createAsyncThunk(
  "admin/fetch-role",
  async (query: any, thunkAPI) => {
    try {      
      
      const response = await axios(
        {
          method: "get",
          url: `/api/admin/fetch-role`,
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateRole = createAsyncThunk(
  "admin/update-role",
  async (postData: any, thunkAPI) => {
    try {
      const response = await axios(
        {
          method: "PUT",
          url: `/api/admin/update-role`,
          data: postData
        }
      );
      let data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e: any) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);